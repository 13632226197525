app.controller('MainCtrl', ['$scope', function($scope) {
  'use strict';
  var stablo = $scope.stablo = {};
  stablo.itemName = 'Obiteljsko stablo';
  stablo.oblacici = 5;
  stablo.selectedType = '42cmx30cm';
  stablo.quantity = 1;

// Obiteljsko stablo
function getObiteljskoStabloPrice(quantity) {
  $scope.$watch('stablo.selectedType', function() {
    if (stablo.selectedType === '42cmx30cm') {
      stablo.price = 199.99;
    }
    if (stablo.selectedType === '60cmx42cm') {
      stablo.price = 229.99;
    }
    if (stablo.selectedType === '84cmx60cm') {
      stablo.price = 299.99;
    }    
    if (stablo.selectedType === '120cmx84cm') {
      stablo.price = 329.99;
    }   
    stablo.quantity = quantity;
    stablo.fullPrice = stablo.price * quantity;
  });
}
$scope.$watch('stablo.quantity', function() {
  getObiteljskoStabloPrice(stablo.quantity);
});


// Adding items to cart
function addItemToCart(item) {
  var cart = [];
  if(localStorage.getItem('cart') === null) {
    cart.push(item);
    localStorage.setItem('cart', JSON.stringify(cart));
  } else {
    var cartLocalStorage = JSON.parse(localStorage.getItem('cart'));
    cartLocalStorage.push(item);
    localStorage.setItem('cart', JSON.stringify(cartLocalStorage));
  }
}

// Cart template data
  $scope.cartData = JSON.parse(localStorage.getItem('cart'));
  $scope.getTotal = function(){
    var total = 0;
    for(var i = 0; i < $scope.cartData.length; i++){
        var product = $scope.cartData[i];
        total += product.fullPrice;
    }
    return total;
};

  // Remy Sharp's debounce
  // https://remysharp.com/2010/07/21/throttling-function-calls
  var debounce = function(fn, delay) {
    var timer = null;
    return function () {
      var context = this, args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    };
  };

  $scope.Naruci = function() {
  try {
      addItemToCart(stablo);
      window.location.replace('/naruci.html');
  }
  catch (e) {
      console.log('Storage failed: ' + e);
      window.confirm('Dogodila se greska prilikom generiranja vaseg sadrzaja. Molimo vas da nas kontaktirate na saga.saga@inet.hr te nam dostavite sadrzaj');
  }
  };

}]);
app.controller('formcontroller', ['$scope', '$http', function($scope, $http){
  $scope.spinner = false;
  $scope.cartData = JSON.parse(localStorage.getItem('cart'));
  $scope.getTotal = function(){
    var total = 0;
    for(var i = 0; i < $scope.cartData.length; i++){
        var product = $scope.cartData[i];
        total += product.fullPrice;
    }
    return total;
};
  $scope.insertData = function(){
    $scope.spinner = true;
    var narudzbaFinall = JSON.parse(localStorage.getItem('cart'));
    $scope.narudzba.ukupno = $scope.getTotal();
    narudzbaFinall.push($scope.narudzba);
   $http({
    method:'POST',
    url: 'https://webshop.erit.hr/api/naruci.php',
    data: JSON.stringify(narudzbaFinall),
   }).success(function(data){
    if(data.error)
    {
     $scope.errorKupac = data.error.kupac;
     $scope.errorMail = data.error.mail;
     $scope.errorMobitel = data.error.mobitel;
     $scope.errorOIB = data.error.OIB;
     $scope.errorMjesto = data.error.mjesto;
     $scope.errorUlicaikucni = data.error.ulicaikucni;
     $scope.errorPostanski = data.error.postanski;
     $scope.errorUkupno = data.error.ukupno;
     $scope.successInsert = null;
     $scope.spinner = false;
    }
    else
    {
     $scope.narudzba = null;
     $scope.errorKupac = null;
     $scope.errorMail = null;
     $scope.errorMobitel = null;
     $scope.errorOIB = null;
     $scope.errorMjesto = null;
     $scope.errorUlicaikucni = null;
     $scope.errorPostanski = null;
     $scope.errorUkupno = null;
     $scope.successInsert = data.message;
     $scope.spinner = false;
     localStorage.removeItem('cart'); 
    }
   });
  };
 }]);
