angular.module('ERITWebShop').run(['$templateCache', function($templateCache) {
  'use strict';

  $templateCache.put('views/kosarica.html',
    "<table style=\"width:100%\" ng-controller=\"MainCtrl\">\r" +
    "\n" +
    "  <tr>\r" +
    "\n" +
    "    <th>Naziv proizvoda</th>\r" +
    "\n" +
    "    <th>Tip</th>\r" +
    "\n" +
    "    <th>Količina</th>\r" +
    "\n" +
    "    <th>Jedinična cijena</th>\r" +
    "\n" +
    "  </tr>\r" +
    "\n" +
    "\r" +
    "\n" +
    "  <tr ng-repeat=\"item in cartData\">\r" +
    "\n" +
    "    <td>{{item.itemName }}</td>\r" +
    "\n" +
    "    <td>{{item.selectedType }}</td>\r" +
    "\n" +
    "    <td>{{item.quantity }}</td>\r" +
    "\n" +
    "    <td>{{item.fullPrice | number: 2 }} kn</td>\r" +
    "\n" +
    "  </tr>\r" +
    "\n" +
    "  <tr>\r" +
    "\n" +
    "    <td></td>\r" +
    "\n" +
    "    <td></td>\r" +
    "\n" +
    "    <td><b>Ukupno:</b></td>\r" +
    "\n" +
    "    <td><b>{{ getTotal() | number: 2 }} kn</b></td>\r" +
    "\n" +
    "  </tr>\r" +
    "\n" +
    "</table>\r" +
    "\n" +
    "<a href=\"/naruci.html\" class=\"box-contact button muted\">\r" +
    "\n" +
    "  <strong>\r" +
    "\n" +
    "    <i class=\"fa fa-cart\"></i>\r" +
    "\n" +
    "    Naruči\r" +
    "\n" +
    "  </strong>\r" +
    "\n" +
    "</a>\r" +
    "\n" +
    "  "
  );


  $templateCache.put('views/naruci.html',
    "<div ng-controller=\"formcontroller\">\r" +
    "\n" +
    "  <div ng-show=\"spinner\">\r" +
    "\n" +
    "    <img src=\"/images/spinner.gif\">\r" +
    "\n" +
    "    <p>Molimo pričekajte, obrađujemo vašu narudžbu.</p>\r" +
    "\n" +
    "  </div>\r" +
    "\n" +
    "  <div ng-hide=\"spinner\">\r" +
    "\n" +
    "    <div class=\"row\" ng-show=\"successInsert\">\r" +
    "\n" +
    "      <div class=\"large-6 columns\">\r" +
    "\n" +
    "        <h3>Narudžba uspješno provedena</h3>\r" +
    "\n" +
    "        <h3>Skeniraj i plati</h3>\r" +
    "\n" +
    "        <img src=\"{{'data:image/jpg;base64,' + successInsert.hubImage}}\" />\r" +
    "\n" +
    "        <p>Uplatu možete izvršiti tako što vašim mobilnim bankarstvom skenirate kod. Poslali smo također i e-mail na vašu adresu u kojem se nalazi PDF putem kojeg možete izvršiti uplatu u banci ili pošti.</p>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "      <div class=\"large-6 columns\">\r" +
    "\n" +
    "        <h3>Informacije</h3>\r" +
    "\n" +
    "        <p>Nakon proknjižene uplate željeni proizvod i račun dostavljamo na Vašu adresu.</p>\r" +
    "\n" +
    "      </div>    \r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "  <div ng-show=\"cartData\">\r" +
    "\n" +
    "    <div class=\"row\" ng-show=\"!successInsert\">\r" +
    "\n" +
    "      <div class=\"large-6 columns\">\r" +
    "\n" +
    "        <h3>Vaši podaci</h3>\r" +
    "\n" +
    "          <form name=\"userForm\" ng-submit=\"insertData()\">\r" +
    "\n" +
    "            <div class=\"form-group\">\r" +
    "\n" +
    "            <label>Ime i prezime ili naziv tvrtke <span class=\"text-danger\">*</span></label>\r" +
    "\n" +
    "            <input type=\"text\" name=\"kupac\" ng-model=\"narudzba.kupac\" class=\"form-control\" />\r" +
    "\n" +
    "            <span class=\"text-danger\" ng-show=\"errorKupac\">{{errorKupac}}</span>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"form-group\">\r" +
    "\n" +
    "              <label>E-mail adresa<span class=\"text-danger\">*</span></label>\r" +
    "\n" +
    "              <input type=\"text\" name=\"mail\" ng-model=\"narudzba.mail\" class=\"form-control\" />\r" +
    "\n" +
    "              <span class=\"text-danger\" ng-show=\"errorMail\">{{errorMail}}</span>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"form-group\">\r" +
    "\n" +
    "              <label>Broj mobitela<span class=\"text-danger\">*</span></label>\r" +
    "\n" +
    "              <input type=\"text\" name=\"mobitel\" ng-model=\"narudzba.mobitel\" class=\"form-control\" />\r" +
    "\n" +
    "              <span class=\"text-danger\" ng-show=\"errorMobitel\">{{errorMobitel}}</span>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"form-group\">\r" +
    "\n" +
    "            <label>OIB <span class=\"text-danger\">*</span></label>\r" +
    "\n" +
    "            <input type=\"text\" name=\"OIB\" ng-model=\"narudzba.OIB\" class=\"form-control\" />\r" +
    "\n" +
    "            <span class=\"text-danger\" ng-show=\"errorOIB\">{{errorOIB}}</span>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"form-group\">\r" +
    "\n" +
    "              <label>Država <span class=\"text-danger\">*</span></label>\r" +
    "\n" +
    "              <select ng-model=\"narudzba.drzava\">\r" +
    "\n" +
    "                <option value=\"Hrvatska\">Hrvatska</option>\r" +
    "\n" +
    "                <option value=\"Njemačka\">Njemačka</option>\r" +
    "\n" +
    "                <option value=\"Austrija\">Austrija</option>\r" +
    "\n" +
    "                <option value=\"BosnaiHercegovina\">Bosna i Hercegovina</option>\r" +
    "\n" +
    "                <option value=\"Srbija\">Srbija</option>\r" +
    "\n" +
    "              </select>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"form-group\">\r" +
    "\n" +
    "            <label>Mjesto <span class=\"text-danger\">*</span></label>\r" +
    "\n" +
    "            <input type=\"text\" name=\"mjesto\" ng-model=\"narudzba.mjesto\" class=\"form-control\" />\r" +
    "\n" +
    "            <span class=\"text-danger\" ng-show=\"errorMjesto\">{{errorMjesto}}</span>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"form-group\">\r" +
    "\n" +
    "            <label>Ulica i kućni broj <span class=\"text-danger\">*</span></label>\r" +
    "\n" +
    "            <input type=\"text\" name=\"ulicaikucni\" ng-model=\"narudzba.ulicaikucni\" class=\"form-control\" />\r" +
    "\n" +
    "            <span class=\"text-danger\" ng-show=\"errorUlicaikucni\">{{errorUlicaikucni}}</span>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "            <div class=\"form-group\">\r" +
    "\n" +
    "              <label>Poštanski broj <span class=\"text-danger\">*</span></label>\r" +
    "\n" +
    "              <input type=\"text\" name=\"postanski\" ng-model=\"narudzba.postanski\" class=\"form-control\" />\r" +
    "\n" +
    "              <input name=\"ukupno\" ng-model=\"narudzba.ukupno\" type=\"hidden\" value=\"65012\">\r" +
    "\n" +
    "              <span class=\"text-danger\" ng-show=\"errorPostanski\">{{errorPostanski}}</span>\r" +
    "\n" +
    "            </div>\r" +
    "\n" +
    "            <div class=\"form-group\">\r" +
    "\n" +
    "            <input type=\"submit\" name=\"insert\" ng-show=\"narudzba\" class=\"box-contact button muted\" value=\"Naruči\"/>\r" +
    "\n" +
    "            <p>Narudžbom se slažete da ste upoznati sa <a href=\"/zastita-podataka.html\">zaštitom podataka</a>.</p>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          </form>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      \r" +
    "\n" +
    "        <div class=\"large-6 columns\">\r" +
    "\n" +
    "          <h3>Stanje košarice</h3>\r" +
    "\n" +
    "          <table style=\"width:100%\" ng-controller=\"MainCtrl\">\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "              <th>Naziv proizvoda</th>\r" +
    "\n" +
    "              <th>Cijena</th>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "          \r" +
    "\n" +
    "            <tr ng-repeat=\"item in cartData\">\r" +
    "\n" +
    "              <td>{{item.itemName }}</td>\r" +
    "\n" +
    "              <td>{{item.fullPrice | number: 2 }} kn</td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "            <tr>\r" +
    "\n" +
    "              <td><b>Ukupno:</b></td>\r" +
    "\n" +
    "              <td><b>{{ getTotal() | number: 2 }} kn</b></td>\r" +
    "\n" +
    "            </tr>\r" +
    "\n" +
    "          </table>\r" +
    "\n" +
    "        </div>    \r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    <div ng-show=\"!cartData\">\r" +
    "\n" +
    "      <h3>Košarica je prazna!</h3>\r" +
    "\n" +
    "      <p>Molimo prvo naručite željeni proizvod kako bi mogli završiti narudžbu.</p>\r" +
    "\n" +
    "  </div>\r" +
    "\n" +
    "  </div>\r" +
    "\n" +
    "</div>"
  );


  $templateCache.put('views/obiteljsko-stablo-sidebar.html',
    "<div class=\"sidebar-right\">\r" +
    "\n" +
    "    <div class=\"callout warning small warning-safari\">\r" +
    "\n" +
    "      You seem to be using Safari. If you're having issues editing or downloading your business card, please try using\r" +
    "\n" +
    "      <a href=\"https://www.google.com/chrome/browser/\" rel=\"noopener\" target=\"_blank\"><strong>Google Chrome</strong></a>.\r" +
    "\n" +
    "    </div>\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <div class=\"help\">\r" +
    "\n" +
    "        <p>Broj oblaka</p>\r" +
    "\n" +
    "        <select ng-model=\"stablo.oblacici\">\r" +
    "\n" +
    "          <option value=\"3\">3</option>\r" +
    "\n" +
    "          <option value=\"4\">4</option>\r" +
    "\n" +
    "          <option value=\"5\">5</option>\r" +
    "\n" +
    "          <option value=\"6\">6</option>\r" +
    "\n" +
    "          <option value=\"7\">7</option>\r" +
    "\n" +
    "          <option value=\"8\">8</option>\r" +
    "\n" +
    "          <option value=\"9\">9</option>\r" +
    "\n" +
    "          <option value=\"10\">10</option>\r" +
    "\n" +
    "          <option value=\"11\">11</option>\r" +
    "\n" +
    "          <option value=\"12\">12</option>\r" +
    "\n" +
    "          <option value=\"13\">13</option>\r" +
    "\n" +
    "          <option value=\"14\">14</option>\r" +
    "\n" +
    "          <option value=\"15\">15</option>\r" +
    "\n" +
    "          <option value=\"16\">16</option>\r" +
    "\n" +
    "          <option value=\"17\">17</option>\r" +
    "\n" +
    "          <option value=\"18\">18</option>\r" +
    "\n" +
    "          <option value=\"19\">19</option>\r" +
    "\n" +
    "          <option value=\"20\">20</option>\r" +
    "\n" +
    "        </select>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        <p>Dimenzije</p>\r" +
    "\n" +
    "        <select ng-model=\"stablo.selectedType\">\r" +
    "\n" +
    "          <option value=\"42cmx30cm\">42cm x 30cm - 199,99 kn</option>\r" +
    "\n" +
    "          <option value=\"60cmx42cm\">60cm x 42cm - 229,99 kn</option>\r" +
    "\n" +
    "          <option value=\"84cmx60cm\">84cm x 60cm - 299,99 kn</option>\r" +
    "\n" +
    "          <option value=\"120cmx84cm\">120cm x 84cm - 329,99 kn</option>\r" +
    "\n" +
    "        </select>\r" +
    "\n" +
    "      <p>Količina obiteljskih stabala</p>\r" +
    "\n" +
    "      <input ng-model=\"stablo.quantity\" type=\"number\" id=\"quantity\" oninput=\"numberOnly(this.id);\" maxlength=\"4\" value=\"1\"/>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <p>Naziv stabla (npr. Obitelj Šimunić)</p>\r" +
    "\n" +
    "      <input placeholder=\"Obitelj Šimunić\" ng-model=\"stablo.ispodStabla\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "      <p>Popunite polja sa imenima</p>\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 3\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 4\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 5\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 6\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 7\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 7</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 7\" ng-model=\"stablo.oblak7\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 8\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 7</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 7\" ng-model=\"stablo.oblak7\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 8</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 8\" ng-model=\"stablo.oblak8\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 9\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 7</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 7\" ng-model=\"stablo.oblak7\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 8</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 8\" ng-model=\"stablo.oblak8\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 9</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 9\" ng-model=\"stablo.oblak9\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 10\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 7</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 7\" ng-model=\"stablo.oblak7\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 8</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 8\" ng-model=\"stablo.oblak8\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 9</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 9\" ng-model=\"stablo.oblak9\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 10</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 10\" ng-model=\"stablo.oblak10\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 11\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 7</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 7\" ng-model=\"stablo.oblak7\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 8</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 8\" ng-model=\"stablo.oblak8\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 9</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 9\" ng-model=\"stablo.oblak9\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 10</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 10\" ng-model=\"stablo.oblak10\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 11</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 11\" ng-model=\"stablo.oblak11\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 12\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 7</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 7\" ng-model=\"stablo.oblak7\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 8</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 8\" ng-model=\"stablo.oblak8\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 9</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 9\" ng-model=\"stablo.oblak9\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 10</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 10\" ng-model=\"stablo.oblak10\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 11</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 11\" ng-model=\"stablo.oblak11\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 12</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 12\" ng-model=\"stablo.oblak12\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 13\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 7</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 7\" ng-model=\"stablo.oblak7\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 8</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 8\" ng-model=\"stablo.oblak8\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 9</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 9\" ng-model=\"stablo.oblak9\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 10</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 10\" ng-model=\"stablo.oblak10\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 11</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 11\" ng-model=\"stablo.oblak11\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 12</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 12\" ng-model=\"stablo.oblak12\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 13</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 13\" ng-model=\"stablo.oblak13\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 14\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 7</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 7\" ng-model=\"stablo.oblak7\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 8</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 8\" ng-model=\"stablo.oblak8\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 9</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 9\" ng-model=\"stablo.oblak9\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 10</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 10\" ng-model=\"stablo.oblak10\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 11</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 11\" ng-model=\"stablo.oblak11\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 12</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 12\" ng-model=\"stablo.oblak12\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 13</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 13\" ng-model=\"stablo.oblak13\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 14</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 14\" ng-model=\"stablo.oblak14\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 15\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 7</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 7\" ng-model=\"stablo.oblak7\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 8</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 8\" ng-model=\"stablo.oblak8\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 9</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 9\" ng-model=\"stablo.oblak9\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 10</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 10\" ng-model=\"stablo.oblak10\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 11</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 11\" ng-model=\"stablo.oblak11\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 12</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 12\" ng-model=\"stablo.oblak12\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 13</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 13\" ng-model=\"stablo.oblak13\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 14</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 14\" ng-model=\"stablo.oblak14\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 15</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 15\" ng-model=\"stablo.oblak15\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 16\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 7</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 7\" ng-model=\"stablo.oblak7\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 8</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 8\" ng-model=\"stablo.oblak8\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 9</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 9\" ng-model=\"stablo.oblak9\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 10</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 10\" ng-model=\"stablo.oblak10\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 11</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 11\" ng-model=\"stablo.oblak11\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 12</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 12\" ng-model=\"stablo.oblak12\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 13</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 13\" ng-model=\"stablo.oblak13\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 14</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 14\" ng-model=\"stablo.oblak14\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 15</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 15\" ng-model=\"stablo.oblak15\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 16</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 16\" ng-model=\"stablo.oblak16\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 17\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 7</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 7\" ng-model=\"stablo.oblak7\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 8</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 8\" ng-model=\"stablo.oblak8\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 9</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 9\" ng-model=\"stablo.oblak9\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 10</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 10\" ng-model=\"stablo.oblak10\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 11</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 11\" ng-model=\"stablo.oblak11\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 12</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 12\" ng-model=\"stablo.oblak12\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 13</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 13\" ng-model=\"stablo.oblak13\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 14</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 14\" ng-model=\"stablo.oblak14\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 15</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 15\" ng-model=\"stablo.oblak15\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 16</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 16\" ng-model=\"stablo.oblak16\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 17</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 17\" ng-model=\"stablo.oblak17\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 18\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 7</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 7\" ng-model=\"stablo.oblak7\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 8</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 8\" ng-model=\"stablo.oblak8\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 9</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 9\" ng-model=\"stablo.oblak9\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 10</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 10\" ng-model=\"stablo.oblak10\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 11</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 11\" ng-model=\"stablo.oblak11\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 12</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 12\" ng-model=\"stablo.oblak12\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 13</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 13\" ng-model=\"stablo.oblak13\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 14</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 14\" ng-model=\"stablo.oblak14\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 15</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 15\" ng-model=\"stablo.oblak15\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 16</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 16\" ng-model=\"stablo.oblak16\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 17</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 17\" ng-model=\"stablo.oblak17\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 18</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 18\" ng-model=\"stablo.oblak18\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 19\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 7</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 7\" ng-model=\"stablo.oblak7\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 8</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 8\" ng-model=\"stablo.oblak8\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 9</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 9\" ng-model=\"stablo.oblak9\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 10</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 10\" ng-model=\"stablo.oblak10\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 11</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 11\" ng-model=\"stablo.oblak11\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 12</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 12\" ng-model=\"stablo.oblak12\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 13</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 13\" ng-model=\"stablo.oblak13\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 14</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 14\" ng-model=\"stablo.oblak14\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 15</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 15\" ng-model=\"stablo.oblak15\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 16</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 16\" ng-model=\"stablo.oblak16\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 17</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 17\" ng-model=\"stablo.oblak17\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 18</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 18\" ng-model=\"stablo.oblak18\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 19</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 19\" ng-model=\"stablo.oblak19\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "      <div ng-if=\"stablo.oblacici == 20\">\r" +
    "\n" +
    "        <div class=\"row\">\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 1</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 1\" ng-model=\"stablo.oblak1\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 2</p>\r" +
    "\n" +
    "            <input placeholder=\"Oblak 2\" ng-model=\"stablo.oblak2\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 3</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 3\" ng-model=\"stablo.oblak3\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 4</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 4\" ng-model=\"stablo.oblak4\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 5</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 5\" ng-model=\"stablo.oblak5\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 6</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 6\" ng-model=\"stablo.oblak6\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 7</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 7\" ng-model=\"stablo.oblak7\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 8</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 8\" ng-model=\"stablo.oblak8\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 9</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 9\" ng-model=\"stablo.oblak9\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 10</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 10\" ng-model=\"stablo.oblak10\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 11</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 11\" ng-model=\"stablo.oblak11\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 12</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 12\" ng-model=\"stablo.oblak12\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 13</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 13\" ng-model=\"stablo.oblak13\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 14</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 14\" ng-model=\"stablo.oblak14\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 15</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 15\" ng-model=\"stablo.oblak15\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 16</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 16\" ng-model=\"stablo.oblak16\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 17</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 17\" ng-model=\"stablo.oblak17\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 18</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 18\" ng-model=\"stablo.oblak18\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 19</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 19\" ng-model=\"stablo.oblak19\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "\r" +
    "\n" +
    "          <div class=\"large-4 columns\">\r" +
    "\n" +
    "            <p>Oblak 20</p>\r" +
    "\n" +
    "          <input placeholder=\"Oblak 20\" ng-model=\"stablo.oblak20\" type=\"text\" maxlength=\"20\" value=\"1\"/>\r" +
    "\n" +
    "          </div>\r" +
    "\n" +
    "        </div>\r" +
    "\n" +
    "      </div>\r" +
    "\n" +
    "      <p>*U jedan oblak unesite najviše 2 imena</p>\r" +
    "\n" +
    "      <p>*Iznad 350,00kn dostava je besplatna</p>\r" +
    "\n" +
    "      <hr>\r" +
    "\n" +
    "      <h3>\r" +
    "\n" +
    "        <b>Cijena: {{ stablo.price * stablo.quantity | number: 2 }} kn</b>\r" +
    "\n" +
    "      </h3>\r" +
    "\n" +
    "      <ul class=\"download-buttons\">\r" +
    "\n" +
    "        <li class=\"order-btn order-btn-psprint\">\r" +
    "\n" +
    "          <button class=\"button muted js-order-btn-psprint js-track-order\" ng-click=\"Naruci()\">\r" +
    "\n" +
    "            <i class=\"fa fa-shopping-cart\"></i>\r" +
    "\n" +
    "            Naruči obiteljsko stablo\r" +
    "\n" +
    "          </button>\r" +
    "\n" +
    "        </li>\r" +
    "\n" +
    "      </ul>\r" +
    "\n" +
    "  </div>\r" +
    "\n" +
    "  </div>"
  );


  $templateCache.put('views/ucitaj-sliku.html',
    "<div class=\"main-card-container\" ng-class=\"{ ' state-loading': model.loadingCard }\">\r" +
    "\n" +
    "  \r" +
    "\n" +
    "    <img src=\"/images/stablo/3.png\" ng-if=\"stablo.oblacici == 3\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "    \r" +
    "\n" +
    "    <img src=\"/images/stablo/4.png\" ng-if=\"stablo.oblacici == 4\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "  \r" +
    "\n" +
    "    <img src=\"/images/stablo/5.png\" ng-if=\"stablo.oblacici == 5\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/6.png\" ng-if=\"stablo.oblacici == 6\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/7.png\" ng-if=\"stablo.oblacici == 7\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/8.png\" ng-if=\"stablo.oblacici == 8\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/9.png\" ng-if=\"stablo.oblacici == 9\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/10.png\" ng-if=\"stablo.oblacici == 10\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/11.png\" ng-if=\"stablo.oblacici == 11\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/12.png\" ng-if=\"stablo.oblacici == 12\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/13.png\" ng-if=\"stablo.oblacici == 13\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/14.png\" ng-if=\"stablo.oblacici == 14\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/15.png\" ng-if=\"stablo.oblacici == 15\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/16.png\" ng-if=\"stablo.oblacici == 16\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/17.png\" ng-if=\"stablo.oblacici == 17\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/18.png\" ng-if=\"stablo.oblacici == 18\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/19.png\" ng-if=\"stablo.oblacici == 19\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "    <img src=\"/images/stablo/20.png\" ng-if=\"stablo.oblacici == 20\" alt=\"Izrada obiteljskog stabla\" title=\"Izrada obiteljskog stabla\">\r" +
    "\n" +
    "\r" +
    "\n" +
    "\r" +
    "\n" +
    "    </div>"
  );

}]);
